.n-text{
    text-align: center;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
}
.todo_container{
    background: #dcdde1;
    border-radius: 6px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
.todo_container li{
    list-style: none;
}
.icons{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.icons>:nth-child(1){
    color: #3c40c6;
}
.icons>:nth-child(2){
    color: var(--themeRed);
}
.icons>:nth-child(3){
    color: #1dd1a1;
}
.eye-title{
    text-align: center;
    align-items: center;
    padding: 1rem;
    font-size: 2rem;
}
.update-title{
    text-align: center;
    align-items: center;
    padding: 1rem;
    font-size: 2rem;

}
