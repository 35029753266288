@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');


html,
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: var(--themeBlack);
  background: var(--background-color);
  overflow-x:hidden;
}

:root{
  --themeRed: #F54748;
  --themeBlack: #2E2E2E;
  --pink: #FDECEC;
  --gray: #828282;
  --shadow: -4px 12px 20px -2px rgba(0, 0, 0, 0.19);
  --background-color:  linear-gradient(180deg, rgba(255, 237, 237, 0.85) 0%, rgba(255, 255, 255, 0) 30rem);
  --cartBack : rgba(255, 255, 255, 0.38);
}
